import { useState } from "react";
import { useParams } from "react-router-dom";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { createVesting } from "../solcial-launch";
import { PublicKey } from "@solana/web3.js";

function StateComponent({ state }) {
  switch (state) {
    case "loading":
      return "loading...";
    case "done":
      return "√";
    case "error":
      return "X";
    default:
      return "";
  }
}
function SetupVesting() {
  const [userFiles, setUserFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const wallet = useWallet();
  const { connection } = useConnection();
  let { mint } = useParams();

  async function setupVesting() {
    mint = new PublicKey(mint || "SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV");

    setLoading(true);
    for (let i = 0; i < userFiles.length; i++) {
      const user = userFiles[i];
      if (!user || !user.address) {
        continue;
      }
      userFiles[i].state = "loading";
      setUserFiles([...userFiles]);
      try {
        let tx = await createVesting(
          connection,
          wallet,
          mint,
          new PublicKey(user.address),
          user.amount
        );
        await connection.confirmTransaction(tx);
        userFiles[i].state = "done";
      } catch (err) {
        console.error(err);
        userFiles[i].state = "error";
      }
      setUserFiles([...userFiles]);
    }
    setLoading(false);
  }

  function csvToArray(str, delimiter = ",") {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  }

  function submitFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      const users = csvToArray(text);
      setUserFiles(users);
    };

    reader.readAsText(file);
  }

  if (!wallet.connected) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
        }}
      >
        <WalletMultiButton />
      </div>
    );
  } else {
    return (
      <div className="App">
        <div className="ButtonRow">
          <input type="file" id="csvFile" accept=".csv" onChange={submitFile} />
          <button onClick={setupVesting} disabled={loading}>
            {loading ? "..." : "Setup vesting"}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>address</th>
              <th>amount</th>
              <th>status</th>
            </tr>
          </thead>
          <tbody>
            {userFiles.map((u, idx) => (
              <tr key={idx}>
                <td>{u.address}</td>
                <td>{u.amount}</td>
                <td>
                  <StateComponent state={u.state} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SetupVesting;
