import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import Claim from "./pages/Claim";
import SetupVesting from "./pages/Setup";

import "./App.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import "react-toastify/dist/ReactToastify.css";
import ListVesting from "./pages/ListVesting";
import { clusterApiUrl } from "@solana/web3.js";
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const wallets = [
  new LedgerWalletAdapter(),
  new PhantomWalletAdapter(),
  new SlopeWalletAdapter(),
  new SolflareWalletAdapter(),
  new SolletExtensionWalletAdapter(),
  new SolletWalletAdapter(),
  new TorusWalletAdapter(),
  new PhantomWalletAdapter(),
];

const AppWithProvider = () => {
  //const endpoint = "https://api.testnet.solana.com";
  //const endpoint = clusterApiUrl('devnet');
  const endpoint = "https://rpc.solcial.io/d1f7b395593bc906f67615a42418bdf2c1670a45";
  return (
    <BrowserRouter>
      <div className="blurred"></div>
      <div className="content">
        <div className="logo">
          <svg
            width="150"
            height="238"
            viewBox="0 0 150 238"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.5608 161.524H48.4351V161.574C1.25246 137.379 -10.6375 88.7512 10.7292 47.0233C32.0959 5.29543 78.4742 -13.5072 125.644 10.7502H125.77L111.467 38.7029L111.341 38.64C84.3814 24.8145 54.8954 34.9323 40.8437 62.3948C26.7919 89.8572 35.8037 119.745 62.7508 133.571H62.8765L48.5608 161.524ZM100.571 76.4335H100.696L100.659 76.3832C147.854 100.578 159.694 149.269 138.365 190.946C117.036 232.624 70.6574 251.414 23.4999 227.207H23.3742L37.6773 199.254H37.803C64.7501 213.092 94.2487 202.962 108.3 175.499C122.352 148.037 113.34 118.149 86.3933 104.323H86.2676L100.571 76.4335Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
            <WalletModalProvider>
              <Routes>
                <Route path="/" element={<Claim />} />
                <Route path="/claim/:mint" element={<Claim />} />
                <Route path="/admin" element={<ListVesting />} />
                <Route path="/admin/csv" element={<SetupVesting />} />
                <Route path="vesting/:mint" element={<SetupVesting />} />
                <Route path="vesting/list" element={<ListVesting />} />
                <Route path="vesting/:mint/list" element={<ListVesting />} />
              </Routes>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </div>
    </BrowserRouter>
  );
};

export default AppWithProvider;
