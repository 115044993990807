import { useEffect, useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import {
  claimSLC,
  StreamInfo,
  listVestings,
  getStreamInfo,
  DECIMAL,
} from "../solcial-launch";

function getDateCounter(d) {
  const _ = (e) => (`${e}`.length === 1 ? `0${e}` : e);

  const unlockTime = d.getTime();
  const currentTime = new Date().getTime();

  let secRemaining = Math.floor((unlockTime - currentTime) / 1000);

  if (secRemaining === 0) window.location.reload();

  let minRemaining = Math.floor(secRemaining / 60);
  secRemaining -= minRemaining * 60;

  let hoursRemaining = Math.floor(minRemaining / 60);
  minRemaining -= hoursRemaining * 60;

  return `${_(hoursRemaining)}:${_(minRemaining)}:${_(secRemaining)}`;
}

function OldClaim() {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [vestings, setVestings] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

  const loadVesting = useCallback(async () => {
    const all = await listVestings(connection, wallet.publicKey);
    const opened = all.map((r) => r.vesting);

    setVestings(opened);
  }, [connection, wallet]);

  useEffect(() => {
    if (wallet != null && wallet.connected) {
      loadVesting();
    }
  }, [wallet, loadVesting]);

  if (!wallet.connected) {
    return (
      <div className="connect-wallet">
        <div className="content__text">
          Claim your <strong>SLCL</strong> tokens.
          <br />
          First connect your wallet securely.
        </div>
        <WalletMultiButton>Connect my wallet</WalletMultiButton>
      </div>
    );
  } else {
    if (!vestings) {
      return (
        <div className="loader__wrapper">
          <div className="loader"></div>
        </div>
      );
    }
    if (vestings.length === 0) {
      return (
        <div className="content__text">
          You have nothing to claim with that wallet. Please try to select
          another one in your browser extension.
        </div>
      );
    }

    return (
      <div>
        <div>
          {vestings.map((v) => (
            <div className="content__text" key={v}>
              <StreamInfo stream={v} />
              {getStreamInfo(v).totalAvailable / DECIMAL <= 0 && (
                <div>
                  <button disabled className="slcl-btn">
                    Claim {v.periodAmount() / DECIMAL} SLCL in{" "}
                    {getDateCounter(v.nextClaimDate(), counter)}
                  </button>
                </div>
              )}
              {getStreamInfo(v).totalAvailable / DECIMAL > 0 && (
                <button
                  className="slcl-btn"
                  onClick={() => {
                    async function claim() {
                      const tx = await claimSLC(connection, wallet, v);
                      await loadVesting();
                      return tx;
                    }

                    toast.promise(claim(), {
                      pending: "Waiting for the transaction",
                      success: {
                        render({ data }) {
                          return `Success ${data}`;
                        },
                        icon: "🟢",
                      },

                      error: "Transaction failed",
                    });
                  }}
                >
                  Claim {getStreamInfo(v).totalAvailable / DECIMAL} SLCL
                </button>
              )}
            </div>
          ))}
        </div>
        <ToastContainer theme="dark" />
      </div>
    );
  }
}

function Claim() {
  return (
    <div className="content__text">
      <p>
        Solcial is now live on the Solana mainnet, therefore the claim page has
        moved to the Solcial app directly.
      </p>

      <p>
        Follow these steps to claim your SLCL tokens:
        <ol>
          <li>
            Visit{" "}
            <a href="https://solcial.io/" className="link">
              https://solcial.io/
            </a>{" "}
            and click on "Connect wallet" to create an account
          </li>
          <li>
            Select the wallet you normally use to claim your SLCL (if you wish
            to receive your SLCL to a new wallet, please email
            investors@solcial.io)
          </li>
          <li>
            Create an account on Solcial with this invite code: <em>A3B2C</em>
          </li>
          <li>
            Once your account has been created, visit this page to claim your
            SLCL (click on "CLAIM REWARDS") :{" "}
            <a href="https://solcial.io/rewards" className="link">
              https://solcial.io/rewards
            </a>
          </li>
          <li>Your vested SLCL are now in your wallet! </li>
        </ol>
      </p>
      <p className="small">
        PS: if you have any questions/suggestions regarding Solcial, feel free
        to ping us on Solcial, or on Telegram, Discord or email
        (investors@solcial.io).
      </p>
    </div>
  );
}

export default Claim;
