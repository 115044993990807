import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { createVesting, listVestings } from "../solcial-launch";
import { PublicKey, Transaction } from "@solana/web3.js";
import { AccountLayout, getAssociatedTokenAddress } from "@solana/spl-token";
import {
  VESTING_PROGRAM_ID,
  getAllSenderVesting,
  getAllVesting,
  updateTotalInstruction,
} from "@solcial.io/vesting";

function ListVesting() {
  const [selectedMint, setSelectedMint] = useState(null);
  const [vestings, setVestings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateVesting, setUpdateVesting] = useState(null);
  const [updateAmount, setUpdateAmount] = useState(0);

  const [createWallet, setCreateWallet] = useState('');

  const wallet = useWallet();
  const { connection } = useConnection();

  useEffect(() => {
    console.log("effect");
    console.log(!wallet, !connection, !connection.connected);
    if (!wallet || !wallet.connected || !connection) {
      return;
    }
    loadVestings();
  }, [wallet, connection]);

  async function loadVestings() {
    console.log(`[+] list accounts fro ${wallet.publicKey}`);
    const res = await getAllVesting(connection, wallet.publicKey);
    const v = res.map((a) => a.vesting);
    v.sort((a, b) => a.config.totalAmount - b.config.totalAmount);
    setVestings(v);
  }

  async function handleUpdate(connection, v, amount) {
    const recipientToken = new PublicKey(v.recipientTokens);
    const [metadataAccount] = await PublicKey.findProgramAddress(
      [recipientToken.toBytes()],
      VESTING_PROGRAM_ID
    );

    console.log("Before build transaction");
    let t = new Transaction().add(
      updateTotalInstruction(
        new PublicKey(v.sender),
        new PublicKey(v.senderTokens),
        metadataAccount,
        new PublicKey(v.escrowTokens),
        Math.floor(amount * 1e9)
      )
    );

    console.log("Before send");
    let res = await wallet.sendTransaction(t, connection);
    console.log("wait for confirmation", res);
    await connection.confirmTransaction(res);
    await loadVestings();
    setUpdateVesting(null);
    setUpdateAmount(0);
    return res;
  }

  if (!wallet.connected) {
    return (
      <div className="App">
        <WalletMultiButton />
      </div>
    );
  }

  if (vestings === null) {
    return <div className="App">List vestings ...</div>;
  }

  return (
    <div className="App">
      {updateVesting && (
        <div className="modal-overlay">
          <div className="modal">
            <input
              value={updateAmount}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                setUpdateAmount(parseInt(e.target.value || 0, 10) || 0);
              }}
            />
            <button
              onClick={async (e) => {
                console.log("Click on button");
                const v = updateVesting;
                if (v == null || updateAmount === null) {
                  console.error("null options");
                  return;
                }

                console.log("handle update");
                let res = await handleUpdate(connection, v, updateAmount);
                console.log(res);
              }}
            >
              Update
            </button>
            <button
              onClick={(e) => {
                console.log("click overlay");
                setUpdateAmount(0);
                setUpdateVesting(null);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {vestings && (
        <table>
          <thead>
            <tr>
              <th>address</th>
              <th>escrow</th>
              <th>vesting amount</th>
              <th>claimed</th>
              <th>update</th>
            </tr>
          </thead>
          <tbody>
            {vestings.map((v, i) => (
              <tr key={i}>
                <td>{new PublicKey(v.recipient).toString()}</td>
                <td>{new PublicKey(v.escrowTokens).toString()}</td>
                <td>{v.config.totalAmount.toNumber() / 1e9}</td>
                <td>{v.withdrawnAmount.toNumber() / 1e9}</td>
                <td>
                  <button
                    onClick={() => {
                      setUpdateAmount(v.config.totalAmount / 1e9);
                      setUpdateVesting(v);
                    }}
                  >
                    update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ListVesting;
