import { Connection, Keypair, PublicKey, Transaction } from "@solana/web3.js";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import {
  vestingInitInstruction,
  withdrawInstruction,
  getAllUserVesting,
  VESTING_PROGRAM_ID,
  Vesting,
  VestingConfig,
} from "@solcial.io/vesting";

export const DECIMAL = 1e9;
/**
 *
 * @param {Connection} connection
 * @param {import("@solana/wallet-adapter-react").WalletContextState} wallet
 * @param {PublicKey} mint
 * @param {PublicKey} recipient
 * @param {number} amount
 * @returns
 */
export async function createVesting(
  connection,
  wallet,
  mint,
  recipient,
  amount
) {
  const walletTokenAccount = await getAssociatedTokenAddress(
    mint,
    wallet.publicKey
  );
  const recipientTokenAccount = await getAssociatedTokenAddress(
    mint,
    recipient
  );
  const [metadataAccount] = await PublicKey.findProgramAddress(
    [recipientTokenAccount.toBytes()],
    VESTING_PROGRAM_ID
  );
  const [escrowAccount] = await PublicKey.findProgramAddress(
    [metadataAccount.toBytes()],
    VESTING_PROGRAM_ID
  );

  let transaction = new Transaction();

  //Wed Mar 23 2022 20:00:00 GMT+0100 (heure normale d’Europe centrale)
  const start = new Date(1648062000000 - 24 * 3600 * 1000);
  const end = new Date(start.getTime() + 2 * 365 * 24 * 3600 * 1000);
  transaction.add(
    vestingInitInstruction(
      mint,
      wallet.publicKey,
      walletTokenAccount,
      recipient,
      recipientTokenAccount,
      metadataAccount,
      escrowAccount,
      Math.floor(start / 1000),
      Math.floor(end / 1000),
      amount * 1e9
    )
  );
  try {
    let tx = await wallet.sendTransaction(transaction, connection);
    return tx;
  } catch (exception) {
    console.error(exception);
    throw new Error(exception);
  }
}

export async function listVestings(connection, pubkey) {
  let all = await getAllUserVesting(connection, pubkey);
  console.log(all);
  return all;
}

/**
 *
 * @param {Connection} connection
 * @param {import("@solana/wallet-adapter-react").WalletContextState} wallet
 * @param {Vesting} vesting
 * @returns
 */
export async function claimSLC(connection, wallet, vesting) {
  const mint = new PublicKey(vesting.mint);

  const recipientTokenAccount = await getAssociatedTokenAddress(
    mint,
    wallet.publicKey
  );
  const [metadataAccount] = await PublicKey.findProgramAddress(
    [recipientTokenAccount.toBytes()],
    VESTING_PROGRAM_ID
  );

  let transaction = new Transaction();

  transaction.add(
    withdrawInstruction(
      wallet.publicKey,
      new PublicKey(vesting.sender),
      new PublicKey(vesting.recipient),
      new PublicKey(vesting.recipientTokens),
      metadataAccount,
      new PublicKey(vesting.escrowTokens),
      mint,
      0
    )
  );

  try {
    let tx = await wallet.sendTransaction(transaction, connection);
    console.log(tx);
    return tx;
  } catch (exception) {
    console.error(exception);
    throw new Error(exception);
  }
}
/**
 *
 * @param {Vesting} v
 */
export function getStreamInfo(stream) {
  return {
    startDate: new Date(stream.config.startTime * 1000),
    endDate: new Date(stream.config.endTime * 1000),
    nextClaimDate: stream.nextClaimDate(),
    totalDeposited: stream.config.totalAmount.toNumber(),
    amountPerPeriod: stream.periodAmount(),
    totalClaimed: stream.withdrawnAmount.toNumber(),
    totalAvailable: stream.available(),
  };
}

export function StreamInfo({ stream }) {
  let info = getStreamInfo(stream);

  return (
    <>
      <p>
        You currently claimed {info.totalClaimed / DECIMAL} out of{" "}
        {info.totalDeposited / DECIMAL}&nbsp;SLCL
      </p>
      {info.totalAvailable / DECIMAL > 0 && (
        <p>
          You can claim {info.totalAvailable / DECIMAL} additional SLCL now!
        </p>
      )}
      {info.totalAvailable / DECIMAL <= 0 && (
        <p>You claimed everything for now, you can come back later.</p>
      )}
    </>
  );
}
